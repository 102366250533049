import React, { useState } from "react";
import "../tailwind.generated.css";
import clsx from "clsx";

import Modal from "./Modal";

import { IconZoom } from "../icons";

type ZoomButtonProps = {
  setIsZoomModalOpen: (isOpen: boolean) => void;
};

type ImageWithZoomProps = {
  imageSrc: string | undefined;
  className?: string;
  onLoad?: () => void;
};

const ImageWithZoom = ({ imageSrc, className, onLoad }: ImageWithZoomProps) => {
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);

  const isMobile = window.screen.width <= 480;

  if (!imageSrc) return null;

  return (
    <>
      <div className="image-with-zoom__wrapper">
        <img
          className={clsx(className && className, "object-contain cursor-zoom-in")}
          src={imageSrc}
          alt=""
          onClick={() => {
            setIsZoomModalOpen(true);
          }}
          onLoad={onLoad}
        />
      </div>
      {isZoomModalOpen && (
        //@ts-ignore
        <Modal isOpen={isZoomModalOpen} setIsOpen={setIsZoomModalOpen} background={false} noHeightLimit noRoundedCorners style={{ height: "100%" }}>
          <div
            className="flex items-center justify-center h-full relative"
            onClick={() => {
              setIsZoomModalOpen(false);
            }}
          >
            <div className={clsx("overflow-auto hideScrollbar", isMobile ? "zoomedImageContainerMobile" : "zoomedImageContainer")}>
              <img src={imageSrc} alt="" className={clsx("object-contain transition-all duration-300 ease-in-out cursor-zoom-out")} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ImageWithZoom;
